import Null from "components/NullComponent";

const blurryTextBox = {
  color: "white",
  textAlign: "center",
  fontWeight: 800,
  fontSize: "4.5vw",
  fontFamily: "Poppins",
  backdropFilter: "blur(70px)",
  WebkitBackdropFilter: "blur(70px)",
  margin: "7vw",
  padding: "1vw",
};

export { blurryTextBox };

// Gatsby requires a default export
// to be a component or string
export default Null;
