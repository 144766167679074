import Null from "components/NullComponent";

const container = {
  textIndent: 0,
  fontSize: "0.5em",
  width: "100%",
};

export { container };

export default Null;
