import Null from "components/NullComponent";

const font = {
  font: {
    family: "Crimson Text, serif",
    size: 14,
  },
};

export { font };

export default Null;
